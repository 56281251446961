import React from "react";
import PropTypes from 'prop-types';

// Style Imports
import '../../sass/components/loader.scss';

// Asset Imports
import Loading from '../../assets/images/icons/loading.svg';

const Loader = ({
    text,
}) => {
    return (
        <div className='loader'>
            <img className='loader__image' src={Loading} alt='' aria-hidden='true' />

            <p className='loader__text'>{text}</p>
        </div>
    );
};

Loader.defaultProps = {
    text: 'Loading...',
};

Loader.propTypes = {
    text: PropTypes.string.isRequired,
};

export default Loader;