import React from 'react';
import PropTypes from 'prop-types';

// Style Imports
import '../../sass/components/button.scss';

const Button = ({
    className,
    variant,
    id,
    type,
    disabled,
    onClick,
    children,
    ariaControls,
    ariaExpanded,
    role,
    icon,
}) => {
    // Class definitions
    const baseClass = 'button';
    const variantClass = variant ? `button--${variant}` : '';
    const disabledClass = disabled ? 'button--disabled' : '';
    const customClass = className;
    const classes = [baseClass, variantClass, disabledClass, customClass].filter(Boolean).join(' ');

    return (
        <button
            className={classes}
            id={id}
            type={type}
            disabled={disabled || ''}
            aria-disabled={disabled || undefined}
            onClick={onClick}
            aria-controls={ariaControls}
            aria-expanded={ariaExpanded}
            role={role}
        >
            {icon && (
                <span className={`button__icon${icon ? ` button__icon--${icon}` : ''}`} aria-hidden='true'></span>
            )}
            <span className='button__text'>
                {children}
            </span>
        </button>
    );
};

Button.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(['disabled', 'icon-only', 'secondary', 'next', 'previous', 'success']),
    id: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired,
    ariaControls: PropTypes.string,
    ariaExpanded: PropTypes.bool,
    role: PropTypes.string,
    icon: PropTypes.oneOf(['add', 'download', 'share']),
};

export default Button;
