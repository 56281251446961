import React from 'react';
import PropTypes from 'prop-types';

// Style Imports
import '../../sass/components/star-rating.scss';

const StarRating = ({
    rating,
    id,
    append,
}) => {
    const renderStars = () => {
        const splitRating = rating.toString().split('.');
        let results = [];

        for (let i = 0; i < splitRating[0]; i++) {
            results.push(
                <span className='star-rating__star' key={`${id}-${i}`}></span>
            )
        }

        if (splitRating[0] < 5 && splitRating[1]) {
            const emptyStarCount = 5 - splitRating[0] - 1;

            results.push(
                <span className='star-rating__star star-rating__star--half' key={`${id}-half`}></span>
            );

            for (let j = 0; j < emptyStarCount; j++) {
                results.push(
                    <span className='star-rating__star star-rating__star--empty' key={`${id}-${j}`}></span>
                )
            }
        }

        if (splitRating[0] < 5 && !splitRating[1]) {
            const emptyStarCount = 5 - splitRating[0];

            for (let j = 0; j < emptyStarCount; j++) {
                results.push(
                    <span className='star-rating__star star-rating__star--empty' key={`${id}-${j}`}></span>
                )
            }
        }

        return results;
    }

    return (
        <div className='star-rating'>
            <p className='accessible'>{rating} stars</p>
            {renderStars()}
            {append && (
                <p className='star-rating__append'>{append}</p>
            )}
        </div>
    );
};

StarRating.propTypes = {
    rating: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    append: PropTypes.string,
};

export default StarRating;