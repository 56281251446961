/* eslint-disable react-hooks/exhaustive-deps */

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

// Style Imports
import '../../sass/components/accordion.scss';

const Accordion = ({
    className,
    variant,
    id,
    buttonText,
    children,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const content = useRef(null);

    // Class definitions
    const baseClass = 'accordion';
    const variantClass = variant ? `accordion--${variant}` : '';
    const customClass = className;
    const activeClass = isOpen ? 'is-open': '';
    const classes = [baseClass, variantClass, customClass, activeClass].filter(Boolean).join(' ');

    const toggleContent = (event) => {
        event.preventDefault();
        setIsOpen(!isOpen);
    };

    return (
        <div className={classes} id={id}>
            <button
                className='accordion__button'
                aria-controls={id + '-content'}
                aria-expanded={isOpen}
                onClick={toggleContent}
            >
                {buttonText}
            </button>
            
            <div
                className='accordion__content-wrapper'
                id={id + '-content'}
                aria-hidden={!isOpen}
            >
                <div className='accordion__content' ref={content} style={{ overflow: 'auto' }}>
                    {children}
                </div>
            </div>
        </div>
    );
};

Accordion.propTypes = {
    id: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    variant: PropTypes.string,
};

export default Accordion;