import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import startCase from "lodash/startCase";

// Components
import InputField from '../../../components/input-field';
import Checkbox from '../../../components/checkbox';
import InputSwitch from '../../../components/input-switch';

const PropertyDetails = () => {
  // React Redux hooks
  const dispatch = useDispatch();
	const query = useSelector(state => state.energyPerformance);

  const handlePropertyChange = (index) => {
    const propertyArray = [...query.propertyType];

    propertyArray[index].selected = !propertyArray[index].selected;

    dispatch({type: 'SET_EPC_PROPERTY_TYPE', payload: propertyArray});
  };
  
  return (
    <div className='step-tracker__split'>
      <div className='step-tracker__column'>
        <div className='step-tracker__row'>
          <InputField
            id='full-partial-address'
            labelText='Full or partial address'
            onChange={(event) => dispatch({ type: 'SET_EPC_ADDRESS', payload: event.target.value })}
            value={query.address}
            textarea
          />
        </div>
        
        <div className='step-tracker__row'>
          <InputField
            id='postcode'
            labelText='Postcode'
            onChange={(event) => dispatch({ type: 'SET_EPC_POSTCODE', payload: event.target.value })}
            value={query.postcode}
            type='text'
          />
        </div>
      </div>

      <div className='step-tracker__column'>
        <div className='step-tracker__row'>
          <Checkbox.Group heading='Property type'>
            {query.propertyType?.map((property, index) => (
              <Checkbox
                id={property.name}
                onChange={() => handlePropertyChange(index)}
                checked={query.propertyType[index].selected}
                key={index}
              >
                {startCase(property.name)}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </div>

        <div className='step-tracker__row'>
          <InputSwitch
            id="is-domestic"
            onChange={() => {
              dispatch({
                type: 'SET_EPC_IS_DOMESTIC',
                payload: !query.isDomestic
              });
            }}
            checked={query.isDomestic}
          >
            Domestic property?
          </InputSwitch>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetails;