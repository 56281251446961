import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// Component imports
import Checkbox from '../checkbox';

// Style imports
import '../../sass/components/epr-selection.scss';

const EPRSelection = ({
    ratings,
    headingLevel,
    dispatchName,
}) => {
    // React Redux hooks
    const dispatch = useDispatch();

    const Heading = headingLevel;

    const handleChange = (index) => {
        const energyRatingArray = [...ratings];
    
        energyRatingArray[index].selected = !energyRatingArray[index].selected;
    
        dispatch({type: dispatchName, payload: energyRatingArray});
    };

    return (
        <div
            className='epr-selection'
            role='group'
            aria-describedby='epr-selection-heading'
        >
            <Heading
                className='epr-selection__heading'
                id='epr-selection-heading'
            >
                Current Energy Rating:
            </Heading>

            {ratings.map((rating, index) => (
                <div className='epr-selection__item' key={index}>
                    <Checkbox
                        className={`epr-selection__checkbox epr-selection__checkbox--${rating.name.toLowerCase()}`}
                        id={rating.name}
                        onChange={() => handleChange(index)}
                        checked={ratings[index].selected}
                    >
                        <>
                            <span className='epr-selection__range'>({rating.range})</span>
                            <span className='epr-selection__name'>{rating.name}</span>
                        </>
                    </Checkbox>
                </div>
            ))}
        </div>
    );
};

EPRSelection.defaultProps = {
    headingLevel: 'h3',
};

EPRSelection.propTypes = {
    ratings: PropTypes.array.isRequired,
    headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    dispatchName: PropTypes.string.isRequired,
};

export default EPRSelection;