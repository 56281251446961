/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';

// Components
import StepTracker from '../../components/step-tracker';

// Partials
import PropertyDetails from './steps/PropertyDetails';
import OtherDetails from './steps/OtherDetails';
import PropertySelection from './steps/PropertySelection';
import Results from './steps/Results';

const EnergyPerformanceRating = ({
    domesticDefinitions,
    nonDomesticDefinitions,
}) => {
    // React Redux hooks
    const dispatch = useDispatch();
	const query = useSelector(state => state.energyPerformance);

    useEffect(() => {
        dispatch({type: 'RESET_EPC_SELECTIONS'});
    }, []);

    const steps = [
        {
            name: 'Property details',
            heading: 'Property details',
            description: "Please enter a full or partial address, postcode or the type of property",
            component: <PropertyDetails />
        },
        {
            name: 'Other details',
            heading: 'Other details',
            description: "Please select any other search criteria to help us narrow down your search",
            component: <OtherDetails />
        },
        {
            name: 'Select a property',
            heading: 'Select a property',
            description: `Showing ${query.data.rows?.length || '0'} result${query.data.rows?.length > 1 ? 's' : typeof query.data.rows?.length == 'undefined' ? 's' : ''}`,
            nextDisabled: true,
            component: <PropertySelection />
        },
        {
            name: 'Energy Performance Information',
            heading: 'Energy Performance Information',
            description: `${query.data?.rows?.[query.selectedPropertyIndex]?.address}, ${query.data?.rows?.[query.selectedPropertyIndex].posttown}, ${query.data?.rows?.[query.selectedPropertyIndex].postcode}`,
            component: <Results domesticDefinitions={domesticDefinitions} nonDomesticDefinitions={nonDomesticDefinitions} />
        },
  	];

    const fetchProperties = () => {
        dispatch({type: 'SET_EPC_LOADING', payload: true});
        
        let params = {
            'address': query.address,
            'postcode': query.postcode,
            'from-month': query.fromDate.getMonth() + 1,
            'from-year': query.fromDate.getFullYear(),
            'to-month': query.toDate.getMonth() + 1,
            'to-year': query.toDate.getFullYear(),
        };

        // Add selected property types to request parameters
        let selectedProperties = [];

        for (let i = 0; i < query.propertyType?.length; i++) {
            if (!!query.propertyType[i].selected) {
                selectedProperties.push(query.propertyType[i].name);
            }

            params = {...params, 'property-type': selectedProperties};
        }

        // Add selected energy ratings to request parameters
        let selectedEnergyBands = [];

        for (let i = 0; i < query.energyRating?.length; i++) {
            if (!!query.energyRating[i].selected) {
                selectedEnergyBands.push(query.energyRating[i].name);
            }

            params = {...params, 'energy-band': selectedEnergyBands};
        }

        axios.get(`https://epc.opendatacommunities.org/api/v1/${!query.isDomestic ? 'non-' : ''}domestic/search`, {
            headers: {
                'Authorization': "Basic amFtLmMucGFya2VyQGdtYWlsLmNvbTo1Mzk1ODk2NjA3MmMwZmJlNmNkYWY5ZjA0NTNmMjJjMjNhOWIwMTVh",
                'Accept': "application/json",
            },
            params: params,
          }).then(response => {
            dispatch({type: 'SET_EPC_RESPONSE_DATA', payload: response.data});
        });
    };

    const handleNext = () => {
        // If 'Other information'
        if (query.currentStep === 2) {
            fetchProperties();
        }
    };

    return (
        <StepTracker
            steps={steps}
            storeName='energyPerformance'
            data={query}
            nextClicked={handleNext}
        />
    );
};

EnergyPerformanceRating.propTypes = {
    domesticDefinitions: PropTypes.array.isRequired,
    nonDomesticDefinitions: PropTypes.array.isRequired,
};

export default EnergyPerformanceRating;