import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

// Component imports
import Label from '../label';
import InfoModal from '../info-modal';

// Style imports
import 'react-datepicker/dist/react-datepicker.css';
import '../../sass/components/date-picker.scss';

const DatePickerComponent = ({
    selectedDate,
    onChange,
    className,
    id,
    label,
    monthPicker,
    value,
    disabled,
    required,
    modalHeading,
    modalContent,
}) => {
    const baseClass = 'date-picker__input';
    const customClass = className;
    const classes = [baseClass, customClass].filter(Boolean).join(' ');

    return (
        <div className='date-picker'>
            <div className='input-field__label-wrapper'>
                <Label
                    className='date-picker__label'
                    htmlFor={id}
                    disabled={disabled}
                    required={required}
                >
                    {label}
                </Label>

                {(modalContent && !disabled) && (
                    <InfoModal heading={modalHeading}>
                        {modalContent}
                    </InfoModal>
                )}
            </div>

            <DatePicker
                className={classes}
                selected={selectedDate}
                value={value}
                onChange={onChange}
                id={id}
                dateFormat={monthPicker ? 'MMMM yyyy' : 'dd-mm-yyyy'}
                showMonthYearPicker={monthPicker}
                disabled={disabled}
                required={required}
            />
        </div>
    );
};

DatePickerComponent.propTypes = {
    selectedDate: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    monthPicker: PropTypes.bool,
    value: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    modalHeading: PropTypes.string,
    modalContent: PropTypes.node,
};

export default DatePickerComponent;