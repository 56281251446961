import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import Table from '../../../components/table';
import InfoModal from '../../../components/info-modal';
import StarRating from '../../../components/star-rating';
import Accordion from '../../../components/accordion';
import Loader from '../../../components/loader';
import EPCRating from '../../../components/epc-rating';
import Content, { HTMLContent } from "../../../components/content-renderer";

// Helpers
import { formatDate, replaceAll } from '../../../helpers/helpers';

const Results = ({
    domesticDefinitions,
    nonDomesticDefinitions,
}) => {
    const PageContent = HTMLContent || Content;

    // React Redux hooks
    const query = useSelector(state => state.energyPerformance);
    const propertyData = query.data?.rows?.[query.selectedPropertyIndex];
    const recommendations = query.recommendations.data?.rows;

    const checkDataPresent = (value) => {
        const emptyValues = [
            ' ',
            'NO DATA!',
            'N/A',
            'None',
        ];

        if (!!!value || emptyValues.includes(value)) {
            return false;
        }

        return true;
    };

    const formatDataValue = (value, units, pre) => {
        let dataValue = `${pre ? pre : ''}${value}${units ? ` ${units}` : ''}`;

        if (!checkDataPresent(value)) {
            dataValue = '-';
        }

        return dataValue;
    };
    
    const squareMetersToFeet = (value) => {
        const conversionRatio = 10.76391041671;

        return (value * conversionRatio).toFixed(2);
    };

    const formatACInspectionCommissioned = (value) => {
        switch (value) {
            case '1':
                return 'Yes, inspection completed';
            case '2':
                return 'Yes, inspection commissioned';
            case '3':
                return 'No inspection completed or commissioned';
            case '4':
                return 'Not relevant';
            case '5':
                return "Don't know";
            default:
                return '-';
        }
    };

    const formatYN = (value) => {
        let dataValue = '';

        const emptyValues = [
            ' ',
            'NO DATA!',
            'N/A',
            'None',
        ];

        if (!!!value || emptyValues.includes(value)) {
            dataValue = '-';
        }
        else if (value === 'y' || value === 'Y') {
            dataValue = 'Yes';
        }
        else if (value === 'n' || value === 'N') {
            dataValue = 'No';
        }
        else {
            dataValue = value;
        }

        return dataValue;
    };

    const formatStarRatings = (value) => {
        let result = 0;

        switch (value.toLowerCase()) {
            case 'very poor':
                result = 1;
                break;
            case 'poor':
                result = 2;
                break;
            case 'average':
                result = 3;
                break;
            case 'good':
                result = 4;
                break;
            case 'very good':
                result = 5;
                break;
            default:
        };

        return result;
    };
    
    return (
        <>
            <h3>Energy rating</h3>
            
            <p style={{marginBottom: '25px'}}>The property has a current energy rating of <strong>{propertyData['environment-impact-current']} ({propertyData['current-energy-rating']})</strong> with the potential of being improved to an energy rating of <strong>{propertyData['environment-impact-potential']} ({propertyData['potential-energy-rating']})</strong></p>
            
            <EPCRating
                current={propertyData['environment-impact-current']}
                potential={propertyData['environment-impact-potential']}
            />

            <h3>Certificate Details</h3>
            
            <Accordion buttonText='General'>
                <Table>
                    <Table.Header className="accessible">
                        <th>Data</th>
                        <th>Value</th>
                    </Table.Header>
                    <tbody>
                        <tr>
                            <td>Certificate type</td>
                            <td>{query.isDomestic ? 'Domestic' : 'Non-domestic'} EPC</td>
                        </tr>
                        <tr>
                            <td>
                                <InfoModal
                                    triggerText='Inspection date'
                                    heading={domesticDefinitions[31].frontmatter.title}
                                >
                                    <PageContent content={domesticDefinitions[31].html} />
                                </InfoModal>
                            </td>
                            <td>{formatDate(formatDataValue(propertyData['inspection-date']))}</td>
                        </tr>
                        <tr>
                            <td>
                                <InfoModal
                                    triggerText='Certificate lodgement date'
                                    heading={domesticDefinitions[5].frontmatter.title}
                                >
                                    <PageContent content={domesticDefinitions[5].html} />
                                </InfoModal>
                            </td>
                            <td>{formatDate(formatDataValue(propertyData['lodgement-datetime']))}</td>
                        </tr>
                        {query.isDomestic && (
                            <>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Current energy rating'
                                            heading={domesticDefinitions[7].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[7].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['current-energy-rating'])}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Potential energy rating'
                                            heading={domesticDefinitions[52].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[52].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['potential-energy-rating'])}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Environmental impact (current)'
                                            heading={domesticDefinitions[11].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[11].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['environment-impact-current'])}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Environmental impact (potential)'
                                            heading={domesticDefinitions[12].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[12].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['environment-impact-potential'])}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Energy consumption (current)'
                                            heading={domesticDefinitions[8].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[8].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['energy-consumption-current'], 'kWh/m²')}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Energy consumption (potential)'
                                            heading={domesticDefinitions[9].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[9].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['energy-consumption-potential'], 'kWh/m²')}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='CO₂ emissions (current)'
                                            heading={domesticDefinitions[2].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[2].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['co2-emissions-current'], 'tonnes per year')}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='CO₂ emissions (potential)'
                                            heading={domesticDefinitions[3].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[3].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['co2-emissions-potential'], 'tonnes per year')}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='CO₂ emissions per floor area (current)'
                                            heading={domesticDefinitions[4].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[4].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['co2-emiss-curr-per-floor-area'], 'kg per m²')}</td>
                                </tr>
                            </>
                        )}
                        {!query.isDomestic && (
                            <>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Asset rating band'
                                            heading={nonDomesticDefinitions[5].frontmatter.title}
                                        >
                                            <PageContent content={nonDomesticDefinitions[5].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['asset-rating-band'])}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Asset rating'
                                            heading={nonDomesticDefinitions[4].frontmatter.title}
                                        >
                                            <PageContent content={nonDomesticDefinitions[4].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['asset-rating'], 'kg CO₂/m²')}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Primary energy use'
                                            heading={nonDomesticDefinitions[12].frontmatter.title}
                                        >
                                            <PageContent content={nonDomesticDefinitions[12].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['primary-energy-value'], 'kWh/m²')}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Standard emissions'
                                            heading={nonDomesticDefinitions[15].frontmatter.title}
                                        >
                                            <PageContent content={nonDomesticDefinitions[15].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['standard-emissions'], 'kg CO₂/m²/year')}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Target emissions'
                                            heading={nonDomesticDefinitions[16].frontmatter.title}
                                        >
                                            <PageContent content={nonDomesticDefinitions[16].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['target-emissions'], 'kg CO₂/m²/year')}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Typical emissions'
                                            heading={nonDomesticDefinitions[17].frontmatter.title}
                                        >
                                            <PageContent content={nonDomesticDefinitions[17].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['typical-emissions'], 'kg CO₂/m²/year')}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Building emissions'
                                            heading={nonDomesticDefinitions[7].frontmatter.title}
                                        >
                                            <PageContent content={nonDomesticDefinitions[7].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['building-emissions'], 'kg CO₂/m²/year')}</td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </Table>
            </Accordion>


            <Accordion buttonText='Location'>
                <Table>
                    <Table.Header className="accessible">
                        <th>Data</th>
                        <th>Value</th>
                    </Table.Header>
                    <tbody>
                        <tr>
                            <td>Address</td>
                            <td>{formatDataValue(propertyData['address'])}</td>
                        </tr>
                        <tr>
                            <td>Town</td>
                            <td>{formatDataValue(propertyData['posttown'])}</td>
                        </tr>
                        <tr>
                            <td>Postcode</td>
                            <td>{formatDataValue(propertyData['postcode'])}</td>
                        </tr>
                        <tr>
                            <td>County</td>
                            <td>{formatDataValue(propertyData['county'])}</td>
                        </tr>
                        <tr>
                            <td>Local authority</td>
                            <td>{propertyData['local-authority-label']}{propertyData['local-authority'] && ` (${propertyData['local-authority']})`}</td>
                        </tr>
                        <tr>
                            <td>Constituency</td>
                            <td>{propertyData['constituency-label']}{propertyData['constituency'] && ` (${propertyData['constituency']})`}</td>
                        </tr>
                    </tbody>
                </Table>
            </Accordion>

            <Accordion buttonText='Tenure'>
                <Table>
                    <Table.Header className="accessible">
                        <th>Data</th>
                        <th>Value</th>
                    </Table.Header>
                    <tbody>
                        <tr>
                            <td>
                                <InfoModal
                                    triggerText='Building reference number'
                                    heading={domesticDefinitions[0].frontmatter.title}
                                >
                                    <PageContent content={domesticDefinitions[0].html} />
                                </InfoModal>
                            </td>
                            <td>{formatDataValue(propertyData['building-reference-number'])}</td>
                        </tr>
                        <tr>
                            <td>
                                <InfoModal
                                    triggerText='Last transaction type'
                                    heading={domesticDefinitions[32].frontmatter.title}
                                >
                                    <PageContent content={domesticDefinitions[32].html} />
                                </InfoModal>
                            </td>
                            <td>{formatDataValue(propertyData['transaction-type'])}</td>
                        </tr>
                        {query.isDomestic && (
                            <>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Construction age band'
                                            heading={domesticDefinitions[6].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[6].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['construction-age-band'])}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Tenure'
                                            heading={domesticDefinitions[62].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[62].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['tenure'])}</td>
                                </tr>
                            </>
                        )}
                        {!query.isDomestic && (
                            <>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='New build benchmark'
                                            heading={nonDomesticDefinitions[10].frontmatter.title}
                                        >
                                            <PageContent content={nonDomesticDefinitions[10].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['new-build-benchmark'])}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Existing stock benchmark'
                                            heading={nonDomesticDefinitions[9].frontmatter.title}
                                        >
                                            <PageContent content={nonDomesticDefinitions[9].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['existing-stock-benchmark'])}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Building complexity level'
                                            heading={nonDomesticDefinitions[6].frontmatter.title}
                                        >
                                            <PageContent content={nonDomesticDefinitions[6].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['building-level'])}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Building environment'
                                            heading={nonDomesticDefinitions[8].frontmatter.title}
                                        >
                                            <PageContent content={nonDomesticDefinitions[8].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['building-environment'])}</td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </Table>
            </Accordion>

            <Accordion buttonText='Construction'>
                <Table>
                    <Table.Header className="accessible">
                        <th>Data</th>
                        <th>Value</th>
                    </Table.Header>
                    <tbody>
                        <tr>
                            <td>
                                <InfoModal
                                    triggerText='Property type'
                                    heading={domesticDefinitions[53].frontmatter.title}
                                >
                                    <PageContent content={domesticDefinitions[53].html} />
                                </InfoModal>
                            </td>
                            <td>{formatDataValue(propertyData['property-type'])}</td>
                        </tr>
                        {query.isDomestic && (
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Built form'
                                        heading={domesticDefinitions[1].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[1].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['built-form'])}</td>
                            </tr>
                        )}
                        <tr>
                            <td>
                                <InfoModal
                                    triggerText='Total floor area'
                                    heading={domesticDefinitions[63].frontmatter.title}
                                >
                                    <PageContent content={domesticDefinitions[63].html} />
                                </InfoModal>
                            </td>
                            <td>{propertyData[`${query.isDomestic ? 'total-' : ''}floor-area`]} m² ({squareMetersToFeet(propertyData[`${query.isDomestic ? 'total-' : ''}floor-area`])} sq.ft)</td>
                        </tr>
                        {query.isDomestic && (
                            <>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Floor level'
                                            heading={domesticDefinitions[20].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[20].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['floor-level'])}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Floor height'
                                            heading={domesticDefinitions[19].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[19].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['floor-height'], 'm')}</td>
                                </tr>
                                {propertyData['flat-top-storey'] && (
                                    <tr>
                                        <td>
                                            <InfoModal
                                                triggerText='Flat top storey'
                                                heading={domesticDefinitions[15].frontmatter.title}
                                            >
                                                <PageContent content={domesticDefinitions[15].html} />
                                            </InfoModal>
                                        </td>
                                        <td>{formatDataValue(propertyData['flat-top-storey'])}</td>
                                    </tr>
                                )}
                                {propertyData['flat-storey-count'] && (
                                    <tr>
                                        <td>
                                            <InfoModal
                                                triggerText='Flat storey count'
                                                heading={domesticDefinitions[14].frontmatter.title}
                                            >
                                                <PageContent content={domesticDefinitions[14].html} />
                                            </InfoModal>
                                        </td>
                                        <td>{formatDataValue(propertyData['flat-storey-count'])}</td>
                                    </tr>
                                )}
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Number of extensions'
                                            heading={domesticDefinitions[48].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[48].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['extension-count'])}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Number of habitable rooms'
                                            heading={domesticDefinitions[49].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[49].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['number-habitable-rooms'])}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Number of open fireplaces'
                                            heading={domesticDefinitions[51].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[51].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['number-open-fireplaces'])}</td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </Table>
            </Accordion>

            {query.isDomestic && (
                <Accordion buttonText='Lighting'>
                    <Table>
                        <Table.Header className="accessible">
                            <th>Data</th>
                            <th>Value</th>
                        </Table.Header>
                        <tbody>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Lighting description'
                                        heading={domesticDefinitions[35].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[35].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['lighting-description'])}</td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Lighting energy efficiency'
                                        heading={domesticDefinitions[36].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[36].html} />
                                    </InfoModal>
                                </td>
                                <td>
                                    {checkDataPresent(propertyData['lighting-energy-eff']) ? (
                                        <StarRating
                                            rating={formatStarRatings(propertyData['lighting-energy-eff'])}
                                            id='lighting-energy-eff'
                                            append={formatDataValue(propertyData['lighting-energy-eff'])}
                                        />
                                    ) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Lighting environmental efficiency'
                                        heading={domesticDefinitions[37].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[37].html} />
                                    </InfoModal>
                                </td>
                                <td>
                                    {checkDataPresent(propertyData['lighting-env-eff']) ? (
                                        <StarRating
                                            rating={formatStarRatings(propertyData['lighting-env-eff'])}
                                            id='lighting-env-eff'
                                            append={formatDataValue(propertyData['lighting-env-eff'])}
                                        />
                                    ) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Lighting cost (current)'
                                        heading={domesticDefinitions[33].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[33].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['lighting-cost-current'], 'per year', '£')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Lighting cost (potential)'
                                        heading={domesticDefinitions[34].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[34].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['lighting-cost-potential'], 'per year', '£')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Low energy lighting'
                                        heading={domesticDefinitions[39].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[39].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['low-energy-lighting'], '% of lighting')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Fixed lighting outlets count'
                                        heading={domesticDefinitions[13].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[13].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['fixed-lighting-outlets-count'], 'outlets')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Low energy fixed lighting outlets count'
                                        heading={domesticDefinitions[38].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[38].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['low-energy-fixed-light-count'], 'outlets')}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Accordion>
            )}
            
            {query.isDomestic && (
                <Accordion buttonText='Heating'>
                    <Table>
                        <Table.Header className="accessible">
                            <th>Data</th>
                            <th>Value</th>
                        </Table.Header>
                        <tbody>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Main heating description'
                                        heading={domesticDefinitions[42].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[42].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['mainheat-description'])}</td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Main heating energy efficiency'
                                        heading={domesticDefinitions[43].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[43].html} />
                                    </InfoModal>
                                </td>
                                <td>
                                    {checkDataPresent(propertyData['mainheat-energy-eff']) ? (
                                        <StarRating
                                            rating={formatStarRatings(propertyData['mainheat-energy-eff'])}
                                            id='mainheat-energy-eff'
                                            append={formatDataValue(propertyData['mainheat-energy-eff'])}
                                        />
                                    ) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Main heating environmental efficiency'
                                        heading={domesticDefinitions[44].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[44].html} />
                                    </InfoModal>
                                </td>
                                <td>
                                    {checkDataPresent(propertyData['mainheat-env-eff']) ? (
                                        <StarRating
                                            rating={formatStarRatings(propertyData['mainheat-env-eff'])}
                                            id='mainheat-env-eff'
                                            append={formatDataValue(propertyData['mainheat-env-eff'])}
                                        />
                                    ) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Heating cost (current)'
                                        heading={domesticDefinitions[24].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[24].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['heating-cost-current'], 'per year', '£')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Heating cost (potential)'
                                        heading={domesticDefinitions[25].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[25].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['heating-cost-potential'], 'per year', '£')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Main heating controls'
                                        heading={domesticDefinitions[41].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[41].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['main-heating-controls'])}</td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Number of heated rooms'
                                        heading={domesticDefinitions[50].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[50].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['number-heated-rooms'])}</td>
                            </tr>
                            {propertyData['secondheat-description'] && (
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Secondary heating description'
                                            heading={domesticDefinitions[57].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[57].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['secondheat-description'])}</td>
                                </tr>
                            )}
                            {propertyData['sheating-energy-eff'] && (
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Secondary heating energy efficiency'
                                            heading={domesticDefinitions[58].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[58].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['sheating-energy-eff'])}</td>
                                </tr>
                            )}
                            {propertyData['sheating-env-eff'] && (
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Secondary heating environmental efficiency'
                                            heading={domesticDefinitions[59].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[59].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['sheating-env-eff'])}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Accordion>
            )}

            {query.isDomestic && (
                <Accordion buttonText='Water'>
                    <Table>
                        <Table.Header className="accessible">
                            <th>Data</th>
                            <th>Value</th>
                        </Table.Header>
                        <tbody>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Hot water description'
                                        heading={domesticDefinitions[28].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[28].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['hotwater-description'])}</td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Hot water energy efficiency'
                                        heading={domesticDefinitions[29].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[29].html} />
                                    </InfoModal>
                                </td>
                                <td>
                                    {checkDataPresent(propertyData['hot-water-energy-eff']) ? (
                                        <StarRating
                                            rating={formatStarRatings(propertyData['hot-water-energy-eff'])}
                                            id='hot-water-energy-eff'
                                            append={formatDataValue(propertyData['hot-water-energy-eff'])}
                                        />
                                    ) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Hot water environmental efficiency'
                                        heading={domesticDefinitions[30].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[30].html} />
                                    </InfoModal>
                                </td>
                                <td>
                                    {checkDataPresent(propertyData['hot-water-env-eff']) ? (
                                        <StarRating
                                            rating={formatStarRatings(propertyData['hot-water-env-eff'])}
                                            id='hot-water-env-eff'
                                            append={formatDataValue(propertyData['hot-water-env-eff'])}
                                        />
                                    ) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Hot water cost (current)'
                                        heading={domesticDefinitions[26].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[26].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['hot-water-cost-current'], 'per year', '£')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Hot water cost (potential)'
                                        heading={domesticDefinitions[27].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[27].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['hot-water-cost-potential'], 'per year', '£')}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Accordion>
            )}

            {query.isDomestic && (
                <Accordion buttonText='Windows'>
                    <Table>
                        <Table.Header className="accessible">
                            <th>Data</th>
                            <th>Value</th>
                        </Table.Header>
                        <tbody>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Windows description'
                                        heading={domesticDefinitions[69].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[69].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['windows-description'])}</td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Windows energy efficiency'
                                        heading={domesticDefinitions[70].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[70].html} />
                                    </InfoModal>
                                </td>
                                <td>
                                    {checkDataPresent(propertyData['windows-energy-eff']) ? (
                                        <StarRating
                                            rating={formatStarRatings(propertyData['windows-energy-eff'])}
                                            id='windows-energy-eff'
                                            append={formatDataValue(propertyData['windows-energy-eff'])}
                                        />
                                    ) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Windows environmental efficiency'
                                        heading={domesticDefinitions[71].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[71].html} />
                                    </InfoModal>
                                </td>
                                <td>
                                    {checkDataPresent(propertyData['windows-env-eff']) ? (
                                        <StarRating
                                            rating={formatStarRatings(propertyData['windows-env-eff'])}
                                            id='windows-env-eff'
                                            append={formatDataValue(propertyData['windows-env-eff'])}
                                        />
                                    ) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Multi glaze proportion'
                                        heading={domesticDefinitions[47].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[47].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['multi-glaze-proportion'], '% of property')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Glazed type'
                                        heading={domesticDefinitions[22].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[22].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['glazed-type'])}</td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Glazed area'
                                        heading={domesticDefinitions[21].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[21].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['glazed-area'])}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Accordion>
            )}

            {query.isDomestic && (
                <Accordion buttonText='Walls'>
                    <Table>
                        <Table.Header className="accessible">
                            <th>Data</th>
                            <th>Value</th>
                        </Table.Header>
                        <tbody>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Walls description'
                                        heading={domesticDefinitions[65].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[65].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['walls-description'])}</td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Walls energy efficiency'
                                        heading={domesticDefinitions[66].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[66].html} />
                                    </InfoModal>
                                </td>
                                <td>
                                    {checkDataPresent(propertyData['walls-energy-eff']) ? (
                                        <StarRating
                                            rating={formatStarRatings(propertyData['walls-energy-eff'])}
                                            id='walls-energy-eff'
                                            append={formatDataValue(propertyData['walls-energy-eff'])}
                                        />
                                    ) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Walls environmental efficiency'
                                        heading={domesticDefinitions[67].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[67].html} />
                                    </InfoModal>
                                </td>
                                <td>
                                    {checkDataPresent(propertyData['walls-env-eff']) ? (
                                        <StarRating
                                            rating={formatStarRatings(propertyData['walls-env-eff'])}
                                            id='walls-env-eff'
                                            append={formatDataValue(propertyData['walls-env-eff'])}
                                        />
                                    ) : '-'}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Accordion>
            )}

            {query.isDomestic && (
                <Accordion buttonText='Flooring'>
                    <Table>
                        <Table.Header className="accessible">
                            <th>Data</th>
                            <th>Value</th>
                        </Table.Header>
                        <tbody>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Floor description'
                                        heading={domesticDefinitions[16].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[16].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['floor-description'])}</td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Floor energy efficiency'
                                        heading={domesticDefinitions[17].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[17].html} />
                                    </InfoModal>
                                </td>
                                <td>
                                    {checkDataPresent(propertyData['floor-energy-eff']) ? (
                                        <StarRating
                                            rating={formatStarRatings(propertyData['floor-energy-eff'])}
                                            id='floor-energy-eff'
                                            append={formatDataValue(propertyData['floor-energy-eff'])}
                                        />
                                    ) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Floor environmental efficiency'
                                        heading={domesticDefinitions[18].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[18].html} />
                                    </InfoModal>
                                </td>
                                <td>
                                    {checkDataPresent(propertyData['floor-env-eff']) ? (
                                        <StarRating
                                            rating={formatStarRatings(propertyData['floor-env-eff'])}
                                            id='floor-env-eff'
                                            append={formatDataValue(propertyData['floor-env-eff'])}
                                        />
                                    ) : '-'}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Accordion>
            )}

            {query.isDomestic && (
                <Accordion buttonText='Roofing'>
                    <Table>
                        <Table.Header className="accessible">
                            <th>Data</th>
                            <th>Value</th>
                        </Table.Header>
                        <tbody>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Roof description'
                                        heading={domesticDefinitions[54].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[54].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['roof-description'])}</td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Roof energy efficiency'
                                        heading={domesticDefinitions[55].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[55].html} />
                                    </InfoModal>
                                </td>
                                <td>
                                    {checkDataPresent(propertyData['roof-energy-eff']) ? (
                                        <StarRating
                                            rating={formatStarRatings(propertyData['roof-energy-eff'])}
                                            id='roof-energy-eff'
                                            append={formatDataValue(propertyData['roof-energy-eff'])}
                                        />
                                    ) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Roof environmental efficiency'
                                        heading={domesticDefinitions[56].frontmatter.title}
                                    >
                                        <PageContent content={domesticDefinitions[56].html} />
                                    </InfoModal>
                                </td>
                                <td>
                                    {checkDataPresent(propertyData['roof-env-eff']) ? (
                                        <StarRating
                                            rating={formatStarRatings(propertyData['roof-env-eff'])}
                                            id='roof-env-eff'
                                            append={formatDataValue(propertyData['roof-env-eff'])}
                                        />
                                    ) : '-'}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Accordion>
            )}

            {!query.isDomestic && (
                <Accordion buttonText='Air conditioning'>
                    <Table>
                        <Table.Header className="accessible">
                            <th>Data</th>
                            <th>Value</th>
                        </Table.Header>
                        <tbody>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Aircon present'
                                        heading={nonDomesticDefinitions[2].frontmatter.title}
                                    >
                                        <PageContent content={nonDomesticDefinitions[2].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['aircon-present'])}</td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Aircon kw rating'
                                        heading={nonDomesticDefinitions[3].frontmatter.title}
                                    >
                                        <PageContent content={nonDomesticDefinitions[3].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['aircon-kw-rating'], 'kW')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Aircon estimated kw rating'
                                        heading={nonDomesticDefinitions[0].frontmatter.title}
                                    >
                                        <PageContent content={nonDomesticDefinitions[0].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatDataValue(propertyData['estimated-aircon-kw-rating'])}</td>
                            </tr>
                            <tr>
                                <td>
                                    <InfoModal
                                        triggerText='Aircon inspection commissioned'
                                        heading={nonDomesticDefinitions[1].frontmatter.title}
                                    >
                                        <PageContent content={nonDomesticDefinitions[1].html} />
                                    </InfoModal>
                                </td>
                                <td>{formatACInspectionCommissioned(propertyData['ac-inspection-commissioned'])}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Accordion>
            )}

            <Accordion buttonText='Energy'>
                <Table>
                    <Table.Header className="accessible">
                        <th>Data</th>
                        <th>Value</th>
                    </Table.Header>
                    <tbody>
                        {query.isDomestic && (
                            <>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Energy tariff'
                                            heading={domesticDefinitions[10].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[10].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['energy-tariff'])}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Mains gas available'
                                            heading={domesticDefinitions[45].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[45].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatYN(propertyData['mains-gas-flag'])}</td>
                                </tr>
                            </>
                        )}
                        <tr>
                            <td>
                                <InfoModal
                                    triggerText='Main fuel type'
                                    heading={domesticDefinitions[40].frontmatter.title}
                                >
                                    <PageContent content={domesticDefinitions[40].html} />
                                </InfoModal>
                            </td>
                            <td>{formatDataValue(propertyData[`main-${query.isDomestic ? '' : 'heating-'}fuel`])}</td>
                        </tr>
                        {query.isDomestic && (
                            <>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Wind turbine count'
                                            heading={domesticDefinitions[68].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[68].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{propertyData['wind-turbine-count'] || 'None'}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Heat loss corridor'
                                            heading={domesticDefinitions[23].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[23].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['heat-loss-corridor'])}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Unheated corridor length'
                                            heading={domesticDefinitions[64].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[64].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['unheated-corridor-length'], 'm²')}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Solar energy supply'
                                            heading={domesticDefinitions[60].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[60].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['photo-supply'], '% of roof area')}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Solar water heating flag'
                                            heading={domesticDefinitions[61].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[61].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatYN(propertyData['solar-water-heating-flag'])}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Mechanical ventilation'
                                            heading={domesticDefinitions[46].frontmatter.title}
                                        >
                                            <PageContent content={domesticDefinitions[46].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['mechanical-ventilation'])}</td>
                                </tr>
                            </>
                        )}
                        {!query.isDomestic && (
                            <>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Other fuel description'
                                            heading={nonDomesticDefinitions[11].frontmatter.title}
                                        >
                                            <PageContent content={nonDomesticDefinitions[11].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['other-fuel-desc'])}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Renewable sources'
                                            heading={nonDomesticDefinitions[13].frontmatter.title}
                                        >
                                            <PageContent content={nonDomesticDefinitions[13].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['renewable-sources'])}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <InfoModal
                                            triggerText='Special energy uses'
                                            heading={nonDomesticDefinitions[14].frontmatter.title}
                                        >
                                            <PageContent content={nonDomesticDefinitions[14].html} />
                                        </InfoModal>
                                    </td>
                                    <td>{formatDataValue(propertyData['special-energy-uses'])}</td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </Table>
            </Accordion>

            <h3>Suggested improvements</h3>

            <div style={{position: 'relative'}}>
                {query.recommendationsLoading ? (
                    <Loader text='Loading recommendations...' />
                ) : (
                    <>
                        {recommendations && recommendations.length ? (
                            <Table>
                                <Table.Header>
                                    <th>Recommendation</th>
                                    <th>Indicative cost</th>
                                </Table.Header>
                                <tbody>
                                    {recommendations.map((recommendation, index) => {
                                        const cost = replaceAll(recommendation['indicative-cost'], '?', '£');

                                        return (
                                            <tr key={index}>
                                                <td>{recommendation['improvement-id-text']}</td>
                                                <td>{cost}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        ) : (
                            <p>There are no recommendations included in the EPC report for this property.</p>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

Results.propTypes = {
    domesticDefinitions: PropTypes.array.isRequired,
    nonDomesticDefinitions: PropTypes.array.isRequired,
};

export default Results;