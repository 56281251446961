/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Style imports
import '../../sass/components/epc-rating.scss';

const EPRSelection = ({
    current,
    potential,
}) => {
    const defaultRatings = [
        {rating: 'A', score: '92-100', current: '', potential: ''},
        {rating: 'B', score: '81-91', current: '', potential: ''},
        {rating: 'C', score: '69-80', current: '', potential: ''},
        {rating: 'D', score: '55-68', current: '', potential: ''},
        {rating: 'E', score: '39-54', current: '', potential: ''},
        {rating: 'F', score: '21-38', current: '', potential: ''},
        {rating: 'G', score: '1-20', current: '', potential: ''},
    ];

    const [ratings, setRatings] = useState(defaultRatings);

    const getRating = (number) => {
        const rating = number >= 92 ? 'A' :
            number < 92 && number >= 81 ? 'B' :
            number < 81 && number >= 69 ? 'C' :
            number < 69 && number >= 55 ? 'D' :
            number < 55 && number >= 39 ? 'E' :
            number < 39 && number >= 21 ? 'F' :
            number < 21 && number >= 0 ? 'G' :
            null;

        return rating;
    };

    const applyRatings = () => {
        const currentRating = getRating(current);
        const potentialRating = getRating(potential);

        const result = defaultRatings.map(item => {
            const update = {...item};

            if (currentRating === item.rating) {
                update.current = current;
            }
            if (potentialRating === item.rating) {
                update.potential = potential;
            }

            return update;
        });

        setRatings(result);
    };

    useEffect(() => {
        applyRatings();
    }, [current, potential]);

    const renderRatingTag = (rating) => {
        const ratingLabel = getRating(rating);

        return (
            <span className={`epc-rating__indicator epc-rating__indicator--${ratingLabel.toLowerCase()}`}>
                <p className='epc-rating__indicator-text'>{rating}</p>
                <p className='epc-rating__indicator-text'>{ratingLabel}</p>
            </span>
        )
    };

    return (
        <div className='epc-rating'>
            <table className='epc-rating__table'>
                <thead className='epc-rating__header'>
                    <tr>
                        <th className='epc-rating__heading'>Score</th>
                        <th className='epc-rating__heading epc-rating__heading--rating'>Energy rating</th>
                        <th className='epc-rating__heading'>Current</th>
                        <th className='epc-rating__heading'>Potential</th>
                    </tr>
                </thead>

                <tbody className='epc-rating__body'>
                    {ratings.map((rating, index) => {
                        const ratingVariant = rating.rating.toLowerCase();

                        return (
                            <tr className='epc-rating__row' key={index}>
                                <td className={`epc-rating__score epc-rating__score--${ratingVariant}`}>{rating.score === '92-100' ? '92+' : rating.score}</td>
                                <td className={`epc-rating__rating epc-rating__rating--${ratingVariant}`}>
                                    <span className='epc-rating__rating-fill'>{rating.rating}</span>
                                </td>
                                <td className='epc-rating__current'>
                                    {rating.current ? renderRatingTag(rating.current) : ''}
                                </td>
                                <td className='epc-rating__potential'>
                                    {rating.potential ? renderRatingTag(rating.potential) : ''}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
};

EPRSelection.propTypes = {
    current: PropTypes.number.isRequired,
    potential: PropTypes.number.isRequired,
};

export default EPRSelection;