import React from 'react';
import PropTypes from 'prop-types';

import Label from '../label';
import InfoModal from '../info-modal';
import Input from '../input';
import Checkbox from '../checkbox';

// Style Imports
import '../../sass/components/input-field.scss';

const InputField = ({
    textarea,
    style,
    className,
    id,
    labelText,
    onChange,
    modalHeading,
    modalContent,
    placeholder,
    disabled,
    max,
    min,
    symbol,
    required,
    type,
    value,
    dataIndex,
    dataName,
    checkboxText,
    checkboxOnChange,
    checkboxValue,
}) => {
    const baseClass = 'input-field';
    const textareaClass = textarea ? 'input-field--textarea' : '';
    const customClass = className;
    const classes = [baseClass, textareaClass, customClass].filter(Boolean).join(' ');

    const Tag = textarea ? 'textarea' : Input;

    return (
        <div className={classes} style={style}>
            <div className='input-field__label-wrapper'>
                <Label
                    htmlFor={`${id}-input`}
                    disabled={disabled}
                    required={required}
                >
                    {labelText}
                </Label>

                {(modalContent && !disabled) && (
                    <InfoModal heading={modalHeading}>
                        {modalContent}
                    </InfoModal>
                )}
            </div>
            
            <div className='input-field__input-wrapper'>
                <Tag
                    className={textarea && 'input-field__textarea'}
                    id={`${id}-input`}
                    name={`${id}-input`}
                    type={textarea ? undefined : type}
                    placeholder={placeholder}
                    symbol={symbol}
                    min={min}
                    max={max}
                    onChange={onChange}
                    disabled={disabled}
                    required={required}
                    value={value}
                    dataindex={textarea ? undefined : dataIndex}
                    dataname={textarea ? undefined : dataName}
                />

                {checkboxText && (
                    <Checkbox
                        id={`${id}-checkbox`}
                        onChange={checkboxOnChange}
                        checked={checkboxValue}
                    >
                        {checkboxText}
                    </Checkbox>
                )}
            </div>
        </div>
    );
};

Input.defaultProps = {
    type: 'number',
};

InputField.propTypes = {
    textarea: PropTypes.bool,
    style: PropTypes.object,
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    modalHeading: PropTypes.string,
    placeholder: PropTypes.string,
    modalContent: PropTypes.node,
    min: PropTypes.number,
    max: PropTypes.number,
    symbol: PropTypes.oneOf(['currency', 'percentage']),
    disabled: PropTypes.bool,
    type: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.any,
    dataIndex: PropTypes.number,
    dataName: PropTypes.string,
    checkboxText: PropTypes.string,
    checkboxValue: PropTypes.bool,
    checkboxOnChange: PropTypes.func,
};

export default InputField;
