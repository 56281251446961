import React from 'react';
import PropTypes from 'prop-types';

// Style Imports
import '../../sass/components/link-list.scss';

const LinkList = ({
    className,
    disabled,
    children,
}) => {
    // Class Definitions
    const baseClass = 'link-list';
    const customClass = className;
    const disabledClass = disabled ? 'link-list' : '';
    const classes = [baseClass, customClass, disabledClass].filter(Boolean).join(' ');

    return (
        <ul className={classes}>
            {children}
        </ul>
    );
};

LinkList.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    disabled: PropTypes.bool,
};

LinkList.Item = ({
    href,
    children,
    onClick,
}) => {
    return (
        <li className='link-list__item'>
            <a
                className='link-list__link'
                href={href}
                onClick={onClick}
            >
                {children}
            </a>
        </li>
    );
};

LinkList.Item.propTypes = {
    href: PropTypes.string.isRequired,
    children: PropTypes.node,
    onClick: PropTypes.func,
};

export default LinkList;
