import React from 'react';
import PropTypes from 'prop-types';

// Style Imports
import '../../sass/components/input.scss';

const Input = ({
    className,
    variant,
    id,
    name,
    value,
    type,
    placeholder,
    describedBy,
    labelledBy,
    disabled,
    required,
    readOnly,
    min,
    max,
    step,
    maxLength,
    onClick,
    onChange,
    checked,
    ariaChecked,
    role,
    symbol,
    dataindex,
    dataname,
}) => {
    // Class definitions
    const baseClass = 'input';
    const variantClass = variant ? `input--${variant}` : '';
    const customClass = className;
    const disabledClass = disabled ? 'input--disabled' : '';
    const requiredClass = required ? 'input--required' : '';
    const classes = [
        baseClass,
        variantClass,
        customClass,
        disabledClass,
        requiredClass,
    ].filter(Boolean).join(' ');

    return (
        <div className={`input__wrapper${symbol === 'currency' ? ' input__wrapper--currency' : symbol === 'percentage' ? ' input__wrapper--percentage' : ''}`}>
            {(symbol === 'currency') && (
                <span className="input__symbol">£</span>
            )}

            <input
                className={classes}
                id={id}
                name={name}
                value={value}
                type={type}
                placeholder={placeholder}
                aria-describedby={describedBy}
                aria-labelledby={labelledBy}
                disabled={disabled || ''}
                aria-disabled={disabled || undefined}
                required={required || ''}
                aria-required={required || undefined}
                readOnly={readOnly || ''}
                aria-readonly={readOnly || undefined}
                min={min}
                max={max}
                step={step}
                maxLength={maxLength}
                onClick={onClick}
                onChange={onChange}
                checked={checked}
                aria-checked={ariaChecked}
                role={role}
                data-idx={dataindex}
                data-name={dataname}
            />

            {(symbol === 'percentage') && (
                <span className="input__symbol">%</span>
            )}
        </div>
    );
};

Input.defaultProps = {
    type: 'text',
};

Input.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    describedBy: PropTypes.string,
    labelledBy: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    maxLength: PropTypes.number,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    ariaChecked: PropTypes.bool,
    role: PropTypes.string,
    symbol: PropTypes.oneOf(['currency', 'percentage']),
    dataindex: PropTypes.number,
    dataname: PropTypes.string,
};

export default Input;
