import React from 'react';
import PropTypes from 'prop-types';

// Component imports
import Label from '../label';

// Style imports
import '../../sass/components/checkbox.scss';

const Checkbox = ({
    className,
    variant,
    id,
    name,
    disabled,
    required,
    children,
    onChange,
    checked,
}) => {
    //Class definitions
    const baseClass = 'checkbox';
    const variantClass = variant ? `checkbox--${variant}` : '';
    const customClass = className;
    const checkedClass = checked ? 'checkbox--checked' : '';
    const classes = [baseClass, variantClass, customClass, checkedClass].filter(Boolean).join(' ');

    return (
        <div className={classes}>
            <input
                className='checkbox__input'
                id={id}
                name={name}
                type="checkbox"
                disabled={disabled}
                required={required}
                checked={checked}
                aria-checked={checked}
                onChange={onChange}
            />
            <Label
                htmlFor={id}
                disabled={disabled}
                required={required}
            >
                {children}
            </Label>
        </div>
    );
};

Checkbox.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    children: PropTypes.node.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
};

Checkbox.Group = ({
    heading,
    headingLevel,
    children,
}) => {
    const Heading = headingLevel;

    return (
        <div className='checkbox__group' role='group'>
            <Heading className='checkbox__group-heading'>{heading}</Heading>

            {children}
        </div>
    );
};

Checkbox.Group.defaultProps = {
    headingLevel: 'h3',
};

Checkbox.Group.propTypes = {
    heading: PropTypes.string.isRequired,
    headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    children: PropTypes.node.isRequired,
};

export default Checkbox;