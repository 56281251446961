import React from 'react';
import PropTypes from 'prop-types';

// Style Imports
import '../../sass/components/label.scss';

const Label = ({
    className,
    variant,
    htmlFor,
    children,
    required,
    disabled,
}) => {
    // Class Definitions
    const baseClass = 'label';
    const variantClass = variant ? `label--${variant}` : '';
    const customClass = className;
    const disabledClass = disabled ? 'label--disabled' : '';
    const classes = [baseClass, variantClass, customClass, disabledClass].filter(Boolean).join(' ');

    return (
        <label className={classes} htmlFor={htmlFor}>
            {children}
            {required ? 
                <>
                    <span className="label__required"> *</span>
                    <span className='accessible'> Required</span>
                </>
            : ''}
        </label>
    );
};

Label.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string,
    htmlFor: PropTypes.string,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
};

export default Label;
