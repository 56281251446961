import React from 'react';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';

// Components
import Loader from '../../../components/loader';
import LinkList from '../../../components/link-list';

const Results = (props) => {
    // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.energyPerformance);

  const getRecommendations = (key) => {
    axios.get(`https://epc.opendatacommunities.org/api/v1/domestic/recommendations/${key}`, {
      headers: {
          'Authorization': "Basic amFtLmMucGFya2VyQGdtYWlsLmNvbTo1Mzk1ODk2NjA3MmMwZmJlNmNkYWY5ZjA0NTNmMjJjMjNhOWIwMTVh",
          'Accept': "application/json",
      },
    })
    .then(response => {
      dispatch({
        type: 'SET_EPC_RECOMMENDATIONS_DATA',
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: 'SET_EPC_RECOMMENDATIONS_LOADING',
        payload: false,
      });
    });
  };

  const handleClick = (event, index, key) => {
    event.preventDefault();

    dispatch({
      type: 'SET_EPC_SELECTED_PROPERTY',
      index: index,
      key: key,
    });
    
    getRecommendations(key);

    props.onNext();
  };

  const handleReset = (event) => {
    event.preventDefault();
    
    dispatch({type: 'SET_ENERGYPERFORMANCE_CURRENT_STEP', payload: 1});
    props.jumpToStep(0);
  };

  const sortedArray = query.data?.rows?.sort((a, b) => a.address.localeCompare(b.address))

  return (
    <>
      <LinkList>
        {sortedArray?.map((item, index) => (
          <LinkList.Item
            href='/'
            onClick={(event) => handleClick(event, index, item['lmk-key'])}
            key={index}
          >
            {item.address}, {item.posttown}, {item.postcode}
          </LinkList.Item>
        ))}
      </LinkList>

      {!!!sortedArray?.length && !!!query.dataLoading && (
        <p style={{textAlign: 'center'}}>No properties matched your search criteria - please <a href='/' onClick={(event) => handleReset(event)}>try again</a>.</p>
      )}

      {query.dataLoading && (
        <Loader text='Searching for properties...' />
      )}
    </>
  );
};

export default Results;