import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import EPRSelection from '../../../components/epr-selection';
import DatePicker from '../../../components/date-picker';

const PropertyDetails = () => {
  // React Redux hooks
  const dispatch = useDispatch();
	const query = useSelector(state => state.energyPerformance);

  return (
    <div className='step-tracker__split'>
      <div className='step-tracker__column'>
        <EPRSelection
          ratings={query.energyRating}
          dispatchName='SET_EPC_ENERGY_RATING'
        />
      </div>
      
      <div className='step-tracker__column'>
        <h3>Certificate lodgement date:</h3>
    
        <div className='step-tracker__row'>
          <DatePicker
            selectedDate={query.fromDate}
            onChange={(date) => dispatch({type: 'SET_EPC_FROM_DATE', payload: date})}
            id='date-picker-from'
            label='From date'
            monthPicker
            value={query.fromDate}
          />
        </div>

        <div className='step-tracker__row'>
          <DatePicker
            selectedDate={query.toDate}
            onChange={(date) => dispatch({type: 'SET_EPC_TO_DATE', payload: date})}
            id='date-picker-to'
            label='To date'
            monthPicker
            value={query.toDate}
          />
        </div>
      </div>
    </div>
  );
};

export default PropertyDetails;